
import { Component, Vue } from 'vue-property-decorator';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import StatusEvent from '@/models/StatusEvent';
import StatusEventConfiguration from '@/models/interfaces/StatusEventConfiguration';
import AddNewStatusEvent from '@/components/views/AutomaticEvents/AddNewStatusEvent.vue';
import { formatDate } from '@/helpers/CmsIndexHelper';
import StatusEventRepository from '@/repositories/StatusEventRepository';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';

@Component({
    name: 'AutomaticEvents',
    components: {
        AddNewStatusEvent,
        SettingsTopBar,
    },
})
export default class AutomaticEvents extends Vue {
    private activeStatus: string = 'ACTIVE';
    private searchQuery: string = '';
    private selectedRowKeys: string[] = [];
    private isNewStatusModalVisible: boolean = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private isExistingStatus: boolean = false;

    private get columns() {
        return [
            { title: this.$t('Name'), dataIndex: 'name', key: 'name' },
            { title: this.$t('Status'), dataIndex: 'status', key: 'status' },
            { title: this.$t('Action'), dataIndex: 'type', key: 'type' },
            { title: this.$t('Creator'), dataIndex: 'creator', key: 'creator' },
            { title: this.$t('Date created'), dataIndex: 'createdAt', key: 'createdAt' },
        ];
    }

    private get statuses(): any[] {
        return StatusEventRepository.getAll().map((statusEvent: StatusEvent) => {
            return {
                key: statusEvent.id,
                name: statusEvent.name,
                status: statusEvent.status,
                type: statusEvent.type,
                creator: statusEvent.madeByUser,
                createdAt: formatDate(statusEvent.createdAt),
            };
        });
    }

    // Filter data by archived state and search query
    private get filteredStatuses() {
        const filteredStatuses = this.statuses.filter((status: StatusEventConfiguration) => {
            return (
                status.status === this.activeStatus &&
                status?.name?.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        });
        return filteredStatuses;
    }

    private get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }
    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }
    private get userRoles() {
        return this.$store.getters['jwtData/userRoles'];
    }

    private get isSuperAdmin() {
        return this.userRoles.includes('ROLE_SUPER_ADMIN');
    }

    private addNew() {
        if (!this.isSuperAdmin) {
            this.$notification.error({
                message: this.$t('Error creating event') as string,
                description: this.$t('You do not have the rights to create event statuses') as string,
            });

            return;
        }

        this.isNewStatusModalVisible = true;
    }

    // Format data and send to BE
    private async createStatusEvent(data: any) {
        let event;
        this.loadingOverlay.start();

        try {
            // API call to the backend to create a new report with the data from the form
            const fetchedEvent = await StatusEvent.createNew(data);
            event = fetchedEvent.data;

            EventBus.$emit(EventBusEvents.createNewActivity, String(event.id));
        } catch (err) {
            this.$notification.error({
                message: this.$t('Error while creating status event configuration!'),
                description: '',
            });
            return;
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Status event configuration successfully created!'),
            description: '',
        });

        this.closePopup();
    }

    // Open modal and pass data to it
    private navigateToEvent(id: string) {
        this.isNewStatusModalVisible = true;
        this.isExistingStatus = true;

        this.$nextTick(() => {
            EventBus.$emit(EventBusEvents.fetchNewStatusEvent, id);
        });
    }

    private confirmDelete() {
        if (!this.isSuperAdmin) {
            this.$notification.error({
                message: this.$t('Error deleting event') as string,
                description: this.$t('You do not have the rights to delete event statuses') as string,
            });

            return;
        }

        if (!this.selectedRowKeys.length) {
            this.$notification.error({
                message: this.$t('Please select at least one row to delete') as string,
                description: '',
            });
            return;
        }

        this.$confirm({
            title: 'Are you sure you want to delete selected events?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                this.deleteStatusEvent();
            },
        });
    }

    private async deleteStatusEvent() {
        this.loadingOverlay.start();

        try {
            for (let i = 0; i < this.selectedRowKeys.length; i++) {
                await StatusEvent.deleteExisting(this.selectedRowKeys[i]);
            }
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: (error as Error).message,
            });
        } finally {
            this.loadingOverlay.stop();
        }

        this.$notification.success({
            message: this.$t('Event configuration successfully deleted!'),
            description: '',
        });
        this.selectedRowKeys = [];

        return Promise.resolve();
    }

    private formatDataForStatusEvent() {
        EventBus.$emit(EventBusEvents.formatDataForNewStatus, this.isExistingStatus);
    }

    private onSelectChange(selectedRowKeys: any) {
        this.selectedRowKeys = selectedRowKeys;
    }

    private closePopup() {
        this.isNewStatusModalVisible = false;
        this.isExistingStatus = false;
    }

    private async fetchStatuses() {
        try {
            await StatusEvent.getAll();
        } catch (error) {
            this.$notification.error({
                message: this.$t('Error deleting report') as string,
                description: (error as Error).message,
            });
        }

        return Promise.resolve();
    }

    private async mounted() {
        this.loadingOverlay.start();
        await this.fetchStatuses();
        this.loadingOverlay.stop();
    }
}
